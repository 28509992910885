import Splide, { Options } from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';
import { PaginationPlugin } from '../carousel';

const options: Options = {
  paginationArrows: true,
  gap: 10,
  grid: {
    cols: 2,
    rows: 4,
    gap: {
      col: 94,
    },
  },
  breakpoints: {
    767: {
      focus: 'center',
      trimSpace: false,
      perPage: 1.2,
      grid: {
        cols: 1,
      },
    },
  },
};

export function initDrugList(rootEl: HTMLElement) {
  try {
    const splide = new Splide(rootEl, options);

    splide.mount({ Grid, PaginationPlugin });

  } catch (e) {
    console.error(e);
  }
}

//initCarouselsWhenReady('.drug-list', initDrugList);
