import { execIfDocumentReady } from '../utils/dom';

const addQueryToHref = () => {
  if (window.location.search) {
    sessionStorage.gtmParameters = window.location.search;
    modifyHref(sessionStorage.gtmParameters);
  } else if (sessionStorage.gtmParameters) {
    modifyHref(sessionStorage.gtmParameters);
  }
}

function modifyHref(gtmStoredVariable) {
  document.querySelectorAll('.dynamic-url').forEach(link => {
    const url = new URL(link.getAttribute('href'));
    const newParams = new URLSearchParams(gtmStoredVariable);

    newParams.forEach((value, key) => {
      url.searchParams.set(key,value);
    });

    return link.setAttribute('href', url.href);
  })
}

execIfDocumentReady(addQueryToHref);
