import _ from 'lodash';
const { cacheVariantExtendedID } = require('./mongo-tracking');


document.addEventListener("DOMContentLoaded", () => {
    global_loaded();
});

var show_console_log_2 = true;

function ready(callback) {
    // in case document is already rendered
    if (document.readyState != 'loading') callback();

    // modern browsers
    else if (document.addEventListener) dcoument.addEventListener('DOMContentLoaded', callback);

    // IE <= 8
    else document.attachEvent('onreadystatechange', function () {
        if (document.readyState === 'complete') callback();
    });
}


function global_loaded() {
    if (show_console_log_2) console.log("global_loaded");
    
    console.log("GO check 1");
    
    // if (go_pages_anti_flickering.includes(window.location.pathname)) start_anti_flickering(1);

    let googleAnalyticsID = window.location.host.includes('boots.com') ? 'UA-60657702-15' : 'UA-60657702-16';
    let googleOptimizeId = window.location.host.includes('boots.com') ? 'OPT-N7M4P9W' : 'OPT-PGGH6HX';
    let src=`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsID}`;
    
    ready(() => {
        console.log("GO check 2");

        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = src;
        var head = document.querySelector("head")
        head.appendChild(s);

        var s2 = document.createElement("script");
        s2.type = "text/javascript";
        s2.src = `https://www.googleoptimize.com/optimize.js?id=${googleOptimizeId}`;
        head.appendChild(s2);

        function gtag() {dataLayer.push(arguments)}

        function addVariantAndExperimentToCookie(value, name) {

            console.log("GO check 4");
            console.log("experiment_ID: ", name);
            console.log("variant_ID: ", value);

            cacheVariantExtendedID(value, name);
        }

        gtag('event', 'optimize.callback', {
            callback: addVariantAndExperimentToCookie
        });

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        // gtag('config', googleAnalyticsID);

        console.log("GO check 3");

    });
}