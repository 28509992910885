function toggleOpenAttribute(element) {
  if (element.hasAttribute('open')) {
    element.removeAttribute('open');
  } else {
    element.setAttribute('open');
  }
}

document.addEventListener('keydown', function (event) {
  if (!(document.activeElement && (event.code === "Enter" || event.keyCode === 13))) {
    return;
  }

  if (document.activeElement.tagName === 'DETAILS') {
    toggleOpenAttribute(document.activeElement);
  }
});
