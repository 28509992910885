const teamMembers = document.querySelectorAll('.team-member');

lineCheck();
window.addEventListener('resize', debounce(lineCheck, 250));

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      timeout = null;

      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function lineCheck() {
  teamMembers.forEach( teamMember => {
    const descriptionContainer = teamMember.querySelector('.team-member__description-container');
    descriptionContainer
      .classList
      .remove('hidden');

    const description = teamMember.querySelector('.team-member__description');

    const descriptionHeight = +window.getComputedStyle(description)
      .height
      .slice(0,-2);

    const lineHeight = +window.getComputedStyle(description)
      .lineHeight
      .slice(0,-2);

    const linesLimitHeight = 7 * lineHeight;

    if ( descriptionHeight > linesLimitHeight) {
      descriptionContainer
        .classList
        .add('hidden');
    }
  });
}


