import { trackScreen } from './mongo-tracking';

window.addEventListener('osano_loaded', () => {
  if (!window.Osano) {
    return;
  }
  window.Osano.cm.addEventListener('osano-cm-consent-saved', () => {
    trackScreen();
    automaticMongoClickTracking();
  })
})
