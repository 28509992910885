import FlexMasonry from 'flexmasonry/src/flexmasonry';
import { execIfDocumentReady } from '../utils/dom';

execIfDocumentReady(() => {
  const grids = document.querySelectorAll('.masonry');

  if (grids.length) {
    FlexMasonry.init('.masonry', {
      responsive: true,
      breakpointCols: {
        'min-width: 1024px': 2,
      },
    });
  }
});
