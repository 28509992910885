function calcElementTop(elem: HTMLElement): number {
  const headers = document.querySelectorAll('.header__container');
  const visibleHeaders = [].filter.call(
    headers,
    (header) => window.getComputedStyle(header).display !== 'none',
  ) as HTMLElement[];
  const headerOffset = visibleHeaders.length ? visibleHeaders[0].offsetHeight : 0;
  const elemRect = elem.getBoundingClientRect();
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

  return elemRect.top + scrollTop - headerOffset;
}


export function scrollToElement(selector: string) {
  requestAnimationFrame(() => {
    const elementToScroll = document.querySelector(selector) as HTMLElement;

    if (elementToScroll) {
      window.scroll({
        top: calcElementTop(elementToScroll),
        behavior: 'smooth',
      });
    }
  });
}
