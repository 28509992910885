function isAuthorized() {
  let cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  cookies.forEach(v => {
    const splitCookie = v.split('=');
      cookieObj[splitCookie[0]] = splitCookie[1];
  })
  return !!cookieObj.authorized
}

function handleAuthMenu() {
  const messages = document.getElementById('messages')
  const account = document.getElementById('account')
  const logIn = document.getElementById('log-in')
  const mobileAccount = document.getElementById('mobile-account')
  const mobileLogIn = document.getElementById('mobile-log-in')
  const mobileMessages = document.getElementById('mobile-messages')

  if(isAuthorized()) {
    messages.classList.add('signed-in')
    account.classList.add('signed-in')
    logIn.classList.add('signed-out')
    mobileAccount.classList.add('signed-in')
    mobileLogIn.classList.add('signed-out')
    mobileMessages.classList.add('signed-in')
  }
  else {
    messages.classList.add('signed-out')
    account.classList.add('signed-out')
    logIn.classList.add('signed-in')
    mobileAccount.classList.add('signed-out')
    mobileLogIn.classList.add('signed-in')
    mobileMessages.classList.add('signed-out')
  }
}

handleAuthMenu()
