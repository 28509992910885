let videos = document.querySelectorAll("video");

let playButtonClickHandler = (e) => {
  const video = e.currentTarget.parentNode.querySelector('video');
  video.play();
};

videos.forEach((videoEl) => {
  let playButton = videoEl.parentNode.querySelector('.video__play-btn');

  playButton.addEventListener('click', playButtonClickHandler);

  videoEl.addEventListener('pause', () => {
    playButton.classList.remove('video__play-btn--hidden');
    playButton.attributes.removeNamedItem('tabindex');
    videoEl.attributes.removeNamedItem('tabindex')
  });

  videoEl.addEventListener('play', () => {
    playButton.classList.add('video__play-btn--hidden');
    playButton.setAttribute('tabindex', '0')
    videoEl.setAttribute('tabindex', '-1')
    videoEl.attributes.removeNamedItem('aria-hidden')
  });
});
