const serviceCodes = {
    // Service Pages
    "/chlamydia-treatment": "CHL",
    "/chlamydia-important-info": "CHL" ,
    "/chlamydia-helpful-info": "CHL",
    "/asthma-inhalers": "AST",
    "/asthma-inhalers-important-info": "AST",
    "/asthma-inhalers-helpful-info": "AST",
    "/acne-treatment-helpful-info": "ACN",
    "/acne-treatment-important-info": "ACN",
    "/acne-treatment": "ACN",
    "/altitude-sickness-helpful-info": "ALT" ,
    "/altitude-sickness-important-info": "ALT",
    "/altitude-sickness": "ALT",
    "/bacterial-vaginosis-helpful-info": "BVT",
    "/bacterial-vaginosis-important-info": "BVT",
    "/bacterial-vaginosis": "BVT",
    "/chlamydia-gonorrhoea-important-info": "T-CG-X",
    "/chlamydia-gonorrhoea": "T-CG-X",
    "/chlamydia-men-important-info": "T-CHL-Y",
    "/chlamydia-men": "T-CHL-Y" ,
    "/chlamydia-women-important-info": "T-CHL-X" ,
    "/chlamydia-women": "T-CHL-X",
    "/cold-sore-helpful-info": "COL" ,
    "/cold-sore-important-info": "COL",
    "/cold-sore-treatment": "COL",
    "/contact-dermatitis-helpful-info": "CON",
    "/contact-dermatitis-important-info": "CON",
    "/contact-dermatitis": "CON",
    "/contraceptive-pill-helpful-info": "ORA",
    "/contraceptive-pill-important-info": "ORA",
    "/contraceptive-pill": "ORA",
    "/cystitis-helpful-info": "CYS",
    "/cystitis-important-info": "CYS",
    "/cystitis-treatment": "CYS",
    "/eczema-treatment-helpful-info": "ECZ",
    "/eczema-treatment-important-info": "ECZ",
    "/eczema-treatment": "ECZ",
    "/epipen-online-helpful-info": "ANA" ,
    "/epipen-online-important-info": "ANA",
    "/epipen-online": "ANA",
    "/erectile-dysfunction-helpful-info": "ERE" ,
    "/erectile-dysfunction-important-info": "ERE",
    "/erectile-dysfunction": "ERE",
    "/facial-hair-removal-important-info": "FAC",
    "/facial-hair-removal": "FAC",
    "/genital-herpes-helpful-info": "GHE" ,
    "/genital-herpes-important-info": "GHE",
    "/genital-herpes": "GHE",
    "/genital-warts-helpful-info": "GEW",
    "/genital-warts-important-info": "GEW",
    "/genital-warts": "GEW",
    "/hayfever-treatment-helpful-info": "HAY" ,
    "/hayfever-treatment-important-info": "HAY",
    "/hayfever-treatment": "HAY",
    "/hba1c-home-test-kit-helpful-info": "T-TYP",
    "/hba1c-home-test-kit-important-info": "T-TYP",
    "/hba1c-home-test-kit": "T-TYP",
    "/herpes-suppression-helpful-info": "GHS" ,
    "/herpes-suppression-important-info": "GHS",
    "/herpes-suppression": "GHS",
    "/hiv-home-test-kit-important-info": "T-HIV",
    "/hiv-home-test-kit": "T-HIV",
    "/HIV-prep-online-helpful-info": "PRE" ,
    "/HIV-prep-online-important-info": "PRE",
    "/HIV-prep-online": "PRE",
    "/HRT-treatment-helpful-info": "MEN",
    "/HRT-treatment-important-info": "MEN",
    "/HRT-treatment-online": "MEN",
    "/jet-lag-helpful-info": "JET",
    "/jet-lag-important-info": "JET",
    "/jet-lag": "JET",
    "/migraine-helpful-info": "MIG",
    "/migraine-important-info": "MIG",
    "/migraine-treatment": "MIG",
    "/morning-after-pill-helpful-info": "MAP",
    "/morning-after-pill-important-info": "MAP",
    "/morning-after-pill": "MAP",
    "/period-delay-important-info": "PDE",
    "/period-delay": "PDE",
    "/period-pain-helpful-info": "PER",
    "/period-pain-important-info": "PER",
    "/period-pain-relief": "PER",
    "/premature-ejaculation-helpful-info": "PRE",
    "/premature-ejaculation-important-info": "PRE",
    "/premature-ejaculation": "PRE",
    "/propecia-online-helpful-info": "HAI",
    "/propecia-online-important-info": "HAI",
    "/propecia-online": "HAI",
    "/psoriasis-helpful-info": "PSO",
    "/psoriasis-important-info": "PSO",
    "/psoriasis-treatment": "PSO",
    "/rosacea-helpful-info": "ROS",
    "/rosacea-important-info": "ROS",
    "/rosacea-treatment": "ROS",
    "/skin-infection-helpful-info": "SKI",
    "/skin-infection-important-info": "SKI",
    "/skin-infection-treatment": "SKI",
    "/sti-home-test-kit-important-info": "T-CG-Y",
    "/sti-home-test-kit": "T-CG-Y",
    "/sti-home-test-msm-important-info": "T-MSMCG-Y",
    "/sti-home-test-msm": "T-MSMCG-Y",
    "/sti-home-test-women-important-info": "T-TOT-X",
    "/sti-home-test-women": "T-TOT-X",
    "/sti-test-kit-important-info": "T-CGT-Y",
    "/sti-test-kit": "T-CGT-Y",
    "/stop-smoking-helpful-info": "STO",
    "/stop-smoking-important-info": "STO",
    "/stop-smoking-treatment": "STO",
    "/swimmers-ear-helpful-info": "SWI",
    "/swimmers-ear-important-info": "SWI",
    "/swimmers-ear": "SWI",
    "/thyroid-test-kit-helpful-info": "T-THY",
    "/thyroid-test-kit-important-info": "T-THY",
    "/thyroid-test-kit": "T-THY",
    "/trichomoniasis-helpful-info": "TRI",
    "/trichomoniasis-important-info": "TRI",
    "/trichomoniasis-test-helpful-info": "T-CGT-X",
    "/trichomoniasis-test": "T-CGT-X",
    "/trichomoniasis": "TRI",
    "/vaginal-dryness-helpful-info": "VAG",
    "/vaginal-dryness-important-info": "VAG",
    "/vaginal-dryness": "VAG",
    "/weight-loss/helpful-info": "WEI",
    "/weight-loss/important-info": "WEI",
    "/weight-loss/treatment": "WEI",

    // Landing Pages
    "/services/acne": "ACN",
    "/services/asthma": "AST",
    "/services/bacterial-vaginosis": "BVT",
    "/services/cold-sore-treatment": "COL",
    "/services/chlamydia": "CHL",
    "/services/contraceptive-pill": "ORA",
    "/services/cystitis": "CYS",
    "/services/eczema": "ECZ",
    "/services/erectile-dysfunction": "ED",
    "/services/facial-hair-removal": "FAC",
    "/services/genital-herpes-episodic": "GHE",
    "/services/genital-warts": "GEW",
    "/services/hair-loss": "HAI",
    "/services/hayfever": "HAY",
    "/services/herpes-suppression": "GHS",
    "/services/HRT-treatment-online": "MEN",
    "/services/migraine-treatment": "MIG",
    "/services/morning-after-pill": "MAP",
    "/services/period-delay": "PDE",
    "/services/period-pain": "PER",
    "/services/premature-ejaculation": "PRE",
    "/services/psoriasis-treatment": "PSO",
    "/services/rosacea": "ROS",
    "/services/weight-loss": "WEI"
};

window.dataLayer = window.dataLayer || [];

let brochurePath = window.location.pathname;

if (brochurePath.includes(".html")) {
    brochurePath = brochurePath.substring(0, brochurePath.lastIndexOf("."))
}

if (brochurePath in serviceCodes) {
    dataLayer.push({
        'event': 'auth_flow',
        'eventCategory': 'auth_flow', // yes exact same
        'eventAction': 'brochure_page', // new event Action type for Brochure Pages
        'eventLabel': [serviceCodes[brochurePath]],
        'signed_in': [ 0 ], // bool
        'new_account': ["na"], // na if na at this step
        'product_name': ["na"], // if the user has specified the product name, 'na' otherwise
        'dosage': ["na"], // if the user has specified the product dosage, 'na' otherwise
        'pharmacy_options': ["na"], // if the user has specified the pharm  'child_service': [child_service], // for flows with nested services. If this hit is on a nested service, which specific path did they take
        'child_service': ["na"], // for flows with nested services. If this hit is on a nested service, which specific path did they take
        'subscription': [ null ] // bool, depending on whether the user is on a subscription for this plan. Just null if unknown or na
    });
}
