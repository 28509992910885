class WeightLossDropDown {
  initialise = function () {
    document.addEventListener("click", e => {
      const isDropdownButton = e.target.matches("[data-dropdown-button]")
      if (!isDropdownButton && e.target.closest("[data-dropdown]") != null) return

      let currentDropdown
      if (isDropdownButton) {
        currentDropdown = e.target.closest("[data-dropdown]")
        currentDropdown.classList.toggle("active")
      }

      document.querySelectorAll("[data-dropdown].active").forEach(dropdown => {
        if (dropdown === currentDropdown) return
        dropdown.classList.remove("active")
      })
    })
  }

  openNav = function () {
    document.getElementById('side-bar').classList.toggle('active')
    document.getElementById('button_active').classList.toggle('button-open')
    document.getElementById('section_nav').classList.toggle('open')
  }
}

export {WeightLossDropDown};
