import { execIfDocumentReady } from '../utils/dom';
import { scrollToElement } from '../utils/scroll';

function addScrollUrlParam(scrollTo: string): void {
  const url = new URL(window.location.toString());

  url.searchParams.set('scrollTo', scrollTo);
  window.history.pushState({}, '', url);
}

function onScrollLinkClick(e: MouseEvent) {
  const targetElementId = (e.target as HTMLElement).dataset.scrollTo;

  if (targetElementId) {
    e.preventDefault();
    addScrollUrlParam(targetElementId);
    scrollToElement(`#${targetElementId}`);
  }
}

function registerClickHandler() {
  const scrollLinks = document.querySelectorAll<HTMLElement>('.scroll-link');

  scrollLinks.forEach(scrollLink => {
    scrollLink.addEventListener('click', onScrollLinkClick);
  });
}

execIfDocumentReady(registerClickHandler);
