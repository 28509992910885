import { Options } from '@splidejs/splide';

/** @type Options */
export const DEFAULT_SPLIDE_OPTIONS: Options = {
  type: 'slide',
  keyboard: 'focused',
  slideFocus: false,
  perPage: 1,
  autoPagination: true,
  classes: {
    prev: 'splide__arrow--prev boots-icon-ic-boots-chevron-thick-left ',
    next: 'splide__arrow--next boots-icon-ic-boots-chevron-thick-right ',
  },
  arrows: true,
};
