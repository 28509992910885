
let latestNewsElement =document.getElementById("latest-news");
if (latestNewsElement && !latestNewsElement.classList.contains("manual-test")) {
      let latestNewsEndPoint = apiUrl + 'content/web/latest-news';

    fetch(latestNewsEndPoint)
      .then(response => {
        return response.json();
      }).then((data) => {

      document.getElementById("latest-news").innerHTML = data.content;

      if (data.title)
        document.getElementById("latest-news-title").innerHTML = data.title;

      if (data.script)
      {
        let e = document.createElement("script");
        e.innerText = data.script;
        document.getElementsByTagName("body")[0].append(e);
      }

    }).catch((ex) => {
      console.log('Could not retrieve latest news information.');
    });
}
