window.config = {
  "production": {
    "production-brochure": {
      "host": "onlinedoctor.boots.com",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://onlinedoctor-clinic.boots.com/",
      "tracking_url": "https://sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "32C1VwLBZV5IO7utJzJBf1WqyzyZ8C048qLkaxxx",
      "cookies_domain": "boots.com",
    }
  },
  "staging": {
    "staging-brochure": {
      "host": "staging-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://staging-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://staging-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://staging-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "oiauG6RFns48yriVX0G5K6cCl8gfuQE66KqG5nA0",
      "cookies_domain": "lemonaidhealth.co.uk",
    }
  },
  "qa": {
    "qa-brochure": {
      "host": "qa-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://qa-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://qa-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://qa-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "SrjGI3Vwup5YGHq6wjd7H4mdXxgZ3vkm5tZOxnph",
      "cookies_domain": "lemonaidhealth.co.uk",
    }
  },
  "dev": {
    "localhost": {
      "host": "local-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "dev-brochure": {
      "host": "dev-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "walter-brochure": {
      "host": "walter-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://walter-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "liam-brochure": {
      "host": "liam-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://liam-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "michael-brochure": {
      "host": "michael-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://michael-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "dev1-brochure": {
      "host": "dev1-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev1-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "dev2-brochure": {
      "host": "dev2-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev2-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "dev3-brochure": {
      "host": "dev3-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev3-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "dev4-brochure": {
      "host": "dev4-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev4-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "dev5-brochure": {
      "host": "dev5-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev5-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "dev6-brochure": {
      "host": "dev6-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev6-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    },
    "dev7-brochure": {
      "host": "dev7-brochure.lemonaidhealth.co.uk",
      "xServiceCode": "BOOTS",
      "xClientName": "lemonaidhealth.co.uk",
      "apiUrl": "https://dev-pd-api.lemonaidpims.co.uk/v2.0/",
      "clinicUrl": "https://dev7-clinic.lemonaidhealth.co.uk/",
      "tracking_url": "https://dev-sapi.lemonaidpims.co.uk/tracking/v1/",
      "tracking_api_key": "gWXRfXrNCtPc8heSBjgzaPya594wK5x5vOgXNK5c",
      "cookies_domain": "lemonaidhealth.co.uk",
    }
  }
};
