import Splide, { Options } from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';
import { PaginationPlugin } from '../carousel';
import { DrugGroupChooserPlugin } from './plugins/drug-group-chooser';

const options: Options = {
  gap: 30,
  grid: {
    cols: 2,
    rows: 1,
    gap: {
      col: 30,
    },
  },
  breakpoints: {
    767: {
      focus: 'center',
      trimSpace: true,
      grid: {
        cols: 1,
      },
    },
  },
};

export function initDrugGroupList(rootEl: HTMLElement) {

  try {
    const splide = new Splide(rootEl, options);

    splide.mount({ Grid, PaginationPlugin, DrugGroupChooserPlugin });
  } catch (e) {
    console.error(e);
  }
}



//initCarouselsWhenReady('.drug-group-list', initDrugGroupList);
