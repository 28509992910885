var bannerParametersUrl = apiUrl + 'content/web/banners';

let bannerTemplateNoLink = `<div class="cmsBanner" style="%style%" id="cmsBanner%id%">
      <div class="cmsBannerImage" style="display:%icondisplay%">
        <img src="https://assets.lemonaidhealth.co.uk/web/brochure/images/%icon%.svg" />
      </div>
      <div class="cmsBannerText">
        %content%
      </div>
    </div>`;

let bannerTemplateLink = `<a href="%url%" class="cmsBannerAnchor">${bannerTemplateNoLink}</a>`;


let banners = "";

fetch(bannerParametersUrl)
  .then(response => {
    return response.json();
  }).then((data) => {

  for (let i = 1; i < 99; i++) {

    if (data[`banner${i}-visible`]) {
      //create banner

      let starttime = data[`banner${i}-starttime`];
      if (starttime){
        //if now is less than start time continue loop
        let parsedStartTime = Date.parse(starttime);
        if (Date.now()<parsedStartTime)
          continue;
      }

      let endtime = data[`banner${i}-endtime`];
      if (endtime){
        //if now is greater than end time continue loop
        let parsedEndTime = Date.parse(endtime);
        if (Date.now()>parsedEndTime)
          continue;
      }

      let urls = data[`banner${i}-urls`];
      if (urls){
        let splitUrls = urls.split(',');
        let found=false;

        let currentUrl = window.location.pathname;
        for(let url of splitUrls){
          let regex = new RegExp(url);

          if (regex.test(currentUrl))
          {
            found=true;
            break;
          }
        }

        if (!found)
          continue;
      }

      let template = "";
      let url = data[`banner${i}-linkurl`];
      let content = data[`banner${i}-content`];
      let icon = data[`banner${i}-icon`];
      let backgroundcolour = data[`banner${i}-backgroundcolour`];
      let textcolour = data[`banner${i}-textcolour`];

      template = template.replace("%id%", `${i}`);

      if (url) {
        template = bannerTemplateLink.replace("%url%", url);
      } else
        template = bannerTemplateNoLink;

      //set banner content
      template = template.replace("%content%", content);

      // icon
      if (icon) {
        template = template.replace("%icondisplay%", "inline");
        template = template.replace("%icon%", icon);
      } else
        template = template.replace("%icondisplay%", "none");

      // style
      let style = "";
      if (backgroundcolour)
        style += `background:${backgroundcolour};`;

      if (textcolour)
        style += `color:${textcolour};`;

      template = template.replace("%style%", style);

      //append to banner container
      banners += template;
    }
  }

  document.getElementById("banner-container").innerHTML = banners;

  //Webinar banner
  let webinarBanner = document.getElementsByClassName("weight-loss-banner")[0];
  if (webinarBanner && data["show-webinar-banner"])
    webinarBanner.style.display="block";

  //10WWLP banner
  let wwlpBanner = document.getElementById("weightLossProgrammeLink");
  if (wwlpBanner && data["show-10wwlp-banner"])
    wwlpBanner.style.display="flex";
});
