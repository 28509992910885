import {Splide} from '@splidejs/splide';
import {Grid} from '@splidejs/splide-extension-grid';
import {PaginationPlugin} from "./carousel";

let lastText = "";

if (document.getElementById("clinician-listing") !== null) {
  function rebind() {
    const card = document.querySelectorAll('.card');
    const closeBtn = document.querySelectorAll('.close_btn');

    card.forEach((c) => {
        c.addEventListener("click", (ev) => {

          if (ev.target.classList.contains('view-full-biography'))
            return;

          if (ev.currentTarget.classList.contains('active'))
            ev.currentTarget.classList.remove('active');
          else
            ev.currentTarget.classList.add('active');
        })
      }
    );
  }

  function populateMobileResults() {
    let parent = document.getElementById("section_accordion");
    let txtSearch = document.getElementById("clinician-search").value;
    let regex = new RegExp(txtSearch, "i");

    for (let element of parent.children) {
      // hide element
      element.style.display = "none";

      let name = element.querySelector('.clinician-name').innerText;
      //let qual = element.querySelector()

      if (txtSearch != "") { //if something is being searched for
        if (regex.test(name) == false) //if no match, continue
          continue;
      }

      // else
      element.style.display = "flex";
    }
  }

  function populateAllCards() {

    document.getElementById("clinician-carousel").innerHTML = `
    <section class="splide">
          <div class="splide__track">
        <ul class="splide__list">
        </ul>
      </div>
    </section>`;

    let list = document.getElementsByClassName("splide__list")[0];
    let template = document.getElementById("clinicianTemplate").innerHTML;

    let txtSearch = document.getElementById("clinician-search").value;

    let html = "";

    let regexs = [];

    let searchTerms = txtSearch.split(" ");

    for (let searchTerm of searchTerms)
      regexs.push(new RegExp(searchTerm, "i"));

    for (let c of clinicianData) {

      //if (txtSearch != "")
      //  if ((regex.test(c.name) === false) && (regex.test(c.jobtitle) === false))
      //    continue;
      let render = true;

      for (let regex of regexs) {
        if ((regex.test(c.name) === false) && (regex.test(c.jobtitle) === false))
        {
          render = false;
          break;
        }
      }

      if (render) {
        if (c.name)
          var populatedCard = template.replaceAll("%name%", c.name);
        populatedCard = populatedCard.replaceAll("%shortbio%", c.shortbio);
        populatedCard = populatedCard.replaceAll("%photo%", c.photo);
        populatedCard = populatedCard.replaceAll("%jobtitle%", c.jobtitle);
        populatedCard = populatedCard.replaceAll("%url%", c.url);
        html += populatedCard;
      }
    }

    if (html === "")
      html = "No data found matching your search criteria";

    list.innerHTML = html;

    let splide = new Splide('.splide', {
      paginationArrows: true,
      //arrows: true,
      // pagination: true,
      grid: {
        rows: 3,
        cols: 3,
        gap: {
          row: '1rem',
          col: '1rem',
        },
      }
    });

    splide.mount({Grid});

    populateMobileResults();

    rebind();
  }

  document.getElementById("clinician-search").addEventListener("keyup", function () {
    let txtSearch = document.getElementById("clinician-search").value;
    if (txtSearch == lastText)
      return;

    lastText = txtSearch;
    populateAllCards();
  });

  populateAllCards();


// closeBtn.forEach((btn) => {
//     btn.addEventListener("click", (ev)=>{
//       console.log("click1");
//       ev.currentTarget.parentElement.parentElement.classList.remove('active');
//       ev.preventDefault();
//     })
//   }
// )
}
