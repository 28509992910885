import { initDrugGroupList } from './drug-group-list';
import { initDrugList } from './drug-list';

declare const CATEGORY_CODE: string;
declare const OUT_OF_STOCK_ENDPOINT: string;

const OUT_OF_STOCK_STRING = 'Out of stock';
// END_POINT is now defined in the .env-cmdrc.json file
//const END_POINT = `./assets/data/{CATEGORY_CODE}.json`;

interface LiveProduct {
  out_of_stock: boolean;
  code: string;
}

function initSplide() {
  const drugGroupList = <HTMLElement>document.querySelector('.drug-group-list');

  if (drugGroupList != null) {
    initDrugGroupList(drugGroupList);
    return;
  }

  const drugList = <HTMLElement>document.querySelector('.drug-list');
  if (drugList != null) {
    initDrugList(drugList);
    return;
  }
}

window.addEventListener('load', () => {
  if (typeof CATEGORY_CODE === 'undefined') {
    initSplide();
    return;
  }

  const END_POINT = OUT_OF_STOCK_ENDPOINT.replace('{CATEGORY_CODE}', CATEGORY_CODE);

  fetch(END_POINT)
    .then(response => {
      return response.json();
    }).then((livedata: LiveProduct[]) => {
      livedata.forEach(function (liveproduct: LiveProduct) {
        if (liveproduct.out_of_stock === true) {
          const elementId = `span[data-id='${liveproduct.code}']`;
          const element = document.querySelector(elementId);

          if (element != null) {
            element.innerHTML = OUT_OF_STOCK_STRING;
            // debugger;
            element.closest('.outofstock-parent')?.classList.add('active');
          }
        }
      });

      initSplide();
      return;
    }).catch(() => {
      initSplide(); //Allow for splide to initialise in case of problems with OOS;
      console.log('Could not retrieve out of stock information.');
    });
});





