import { execIfDocumentReady } from '../utils/dom';
import { scrollToElement } from '../utils/scroll';

function autoScroll() {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);

  if (urlParams.has('scrollTo')) {
    const blockId = urlParams.get('scrollTo');

    if (blockId) {
      execIfDocumentReady(scrollToElement.bind(undefined, `#${blockId}`));
    }
  }
}

autoScroll();
