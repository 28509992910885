import { execIfDocumentReady } from '../../utils/dom';

type InitFunction = (rootEl: HTMLElement) => void;

const configureCarousels = (selector: string, initFunction: InitFunction) => {
  const carousels = document.querySelectorAll<HTMLElement>(selector);

  carousels.forEach(initFunction);
};

export function initCarouselsWhenReady(selector: string, initFunction: InitFunction) {
  const init = configureCarousels.bind(undefined, selector, initFunction);

  execIfDocumentReady(init);
}
