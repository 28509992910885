window.addEventListener("DOMContentLoaded", function () {

    //find elements by class
    let allbuttons = document.getElementsByClassName("LiviGP");
    for (let button of allbuttons) {
      console.log("livi attaching to " + button);
  
      button.onclick = function (event) {
        let navigationStarted=false;
  
        gtag("event", "get-started-click", {
          event_category: "home-page-click-livi-gp",
          event_label: "home-page-click-livi-gp",
          transport_type: "beacon",
          event_callback: () => {
            if (navigationStarted)
              return;
  
            navigationStarted=true;
            console.log("callback executed");
            document.location = button.href;
          },
        });
  
        return false;
      };
    }
  
  });
  