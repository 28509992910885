import { ComponentConstructor, EventMap } from '@splidejs/splide';

export const DrugGroupChooserPlugin: ComponentConstructor = (splide) => {
  const DRUG_SELECTOR = '.new-drug';
  const BTN_CLASS = 'new-drug__navigation__btn';
  const BTN_ACTIVE_CLASS = 'new-drug__navigation__btn--active';
  const CONTENT_SELECTOR = '.new-drug__content';
  const CONTENT_ACTIVE_CLASS = 'new-drug__content--active';

  function activateButton(btn: HTMLButtonElement) {
    const buttons = btn?.parentElement?.querySelectorAll(`.${BTN_CLASS}`) ?? [];
    const contents = btn.closest(DRUG_SELECTOR)?.querySelectorAll(CONTENT_SELECTOR);
    const currentIndex = ([] as HTMLButtonElement[]).indexOf.call(buttons, btn);

    buttons.forEach((item) => item.classList.remove(BTN_ACTIVE_CLASS));
    btn.classList.add(BTN_ACTIVE_CLASS);

    contents?.forEach((content) => content.classList.remove(CONTENT_ACTIVE_CLASS));
    contents?.[currentIndex].classList.add(CONTENT_ACTIVE_CLASS);
  }

  function onButtonClick(e: MouseEvent) {
    if (!(e.target as HTMLButtonElement).classList.contains(BTN_CLASS)) {
      return;
    }

    activateButton(e.target as HTMLButtonElement);
  }

  const onMounted: EventMap['mounted'] = () => {
    splide.root.addEventListener('click', onButtonClick as EventListener);
  };

  return {
    setup() {
      splide.on('mounted', onMounted);
    },
  };
};
