window.addEventListener("DOMContentLoaded", function () {

  //find elements by class
  let allbuttons = document.getElementsByClassName("get-started-btn");
  for (let button of allbuttons) {
    console.log("attaching to " + button);

    button.onclick = function (event) {
      let gavalue = "";

      if (typeof CATEGORY_CODE != "undefined")
        gavalue = CATEGORY_CODE;

      console.log("ga cat: " + gavalue);

      let navigationStarted=false;

      gtag("event", "get-started-click", {
        event_category: "brochure",
        event_label: gavalue,
        transport_type: "beacon",
        event_callback: () => {
          if (navigationStarted)
            return;

          navigationStarted=true;
          console.log("callback executed");
          document.location = button.href;
        },
      });

      return false;
    };
  }

});
