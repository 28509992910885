import _ from 'lodash';



var host = window.location.host;
var apiUrl;
var clinicUrl;
var tracking_url;
var tracking_api_key;
var cookies_domain;

var xServiceCode;
var xClientName;
var defaultUrl = true;

// BOOTS AND LEMONAID URLS (FOR DEVELOPERS USING EC2 / FOR LOCAL DEPLOYMENT & SELF TESTING)
Object.values(window.config).forEach(group => {
  Object.values(group).forEach(item => {
    if(host == item.host){
      createVariable(item);

      defaultUrl = false;
    }
  });
});

if (defaultUrl) {
  xServiceCode = window.config.dev["dev-brochure"].xServiceCode;
  xClientName = window.config.dev["dev-brochure"].xClientName;
  apiUrl = window.config.dev["dev-brochure"].apiUrl;
  clinicUrl = window.config.dev["dev-brochure"].clinicUrl;
  tracking_url = window.config.dev["dev-brochure"].tracking_url;
  tracking_api_key = window.config.dev["dev-brochure"].tracking_api_key;
  cookies_domain = window.config.dev["dev-brochure"].cookies_domain;
}

function createVariable(data) {
  xServiceCode = data.xServiceCode;
  xClientName = data.xClientName;
  apiUrl = data.apiUrl;
  clinicUrl = data.clinicUrl;
  tracking_url = data.tracking_url;
  tracking_api_key = data.tracking_api_key;
  cookies_domain = data.cookies_domain;
}

//******** ENDPOINTS *********//

var tracking_base = tracking_url + 'visits';
var tracking_uuid = tracking_url + 'uuid';

var trackMongoPayload = {};

window.automaticMongoClickTracking = function() {
  document.onclick = function(event) {
    if(!window.Osano.cm.analytics) {
      return;
    }

    function processLink(innerText, href, elem) {
      var target = elem.getAttribute('target');

      if (href) event.preventDefault();

      trackAction("click", [ window.location.pathname, innerText ? innerText : "empty", "link", href ? href : "not found" ], href ? { href: href, target: target ? target : "" } : null);
    }

    function processButton(innerText, elem) {
      var onClick = elem.getAttribute('onclick');

      trackAction("click", [ window.location.pathname, innerText ? innerText : "empty", "button", onClick ? onClick : "not found" ], null);
    }

    var elem = event.target;
    var parent = event.target.parentElement;
    var grandParent = event.target.parentElement.parentElement;

    if (elem && elem.tagName.toLowerCase() === 'a') {

      processLink(event.target.innerText, event.target.href, elem);

    } else if (elem && elem.tagName.toLowerCase() === 'button') {

      processButton(event.target.innerText, elem);

    } else if (parent && parent.tagName.toLowerCase() === 'a') {

      processLink(event.target.parentElement.innerText, event.target.parentElement.href, parent);

    } else if (parent && parent.tagName.toLowerCase() === 'button') {

      processButton(event.target.parentElement.innerText, parent);

    } else if (grandParent && grandParent.tagName.toLowerCase() === 'a') {

      processLink(event.target.parentElement.parentElement.innerText, event.target.parentElement.parentElement.href, grandParent);

    } else if (grandParent && grandParent.tagName.toLowerCase() === 'button') {

      processButton(event.target.parentElement.parentElement.innerText, grandParent);
    }
  }
}

var trackAction = function (action, attribute, urlToGo) {

  var payload = {};
  var actionDetails = [];

  payload.actionLog = {};
  payload.actionLog.event = action;

  if (attribute && Array.isArray(attribute) && attribute.length) {

    attribute.forEach( function (data, index) {

      if (index === 0 && data) {
        payload.actionLog.screen = data;
      }
      if (index === 1 && data) {
        payload.actionLog.label = data;
      }
      if (index === 2 && data) {
        payload.actionLog.type = data;
      }
      if (index === 3 && data) {
        payload.actionLog.target = data;
      }
      if (index > 3 && data) {
        actionDetails.push(data);
      }
    });

    payload.actionLog.details = actionDetails;

  } else if (attribute && attribute.dataArray && Array.isArray(attribute.dataArray) && attribute.dataArray.length) {

    attribute.dataArray.forEach( function (data, index) {

      if (index === 0 && data) {
        payload.actionLog.screen = data;
      }
      if (index === 1 && data) {
        payload.actionLog.label = data;
      }
      if (index === 2 && data) {
        payload.actionLog.type = data;
      }
      if (index === 3 && data) {
        payload.actionLog.target = data;
      }
      if (index > 3 && data) {
        actionDetails.push(data);
      }
    });

    payload.actionLog.details = actionDetails;

  } else if (attribute && attribute.actionLog) {

    payload.actionLog = attribute.actionLog;
    payload.actionLog.event = action;

  }

  trackMongoPayload = Object.assign(trackMongoPayload, payload);
  trackMongo(urlToGo);
}

var trackMongo = function(urlToGo) {

  // PLEASE DO NOT DELETE
  // DATE CONVERSIONS
  /*
  var dateLocal = (new Date()).toISOString().substring(0, 10);;
  var datePacific = (new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}))).toISOString().substring(0, 10);
  var dateNewYork = (new Date(new Date().toLocaleString("en-US", {timeZone: "America/New_York"}))).toISOString().substring(0, 10);
  var dateSydney = (new Date(new Date().toLocaleString("en-US", {timeZone: "Australia/Sydney"}))).toISOString().substring(0, 10);

  console.log('dateLocal:', dateLocal);
  console.log('datePacific:', datePacific);
  console.log('dateNewYork:', dateNewYork);
  console.log('dateSydney:', dateSydney);
  */

  trackMongoPayload.date_pacific = (new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}))).toISOString().substring(0, 10);

  var searchParsed = getUrlParameters(window.location.search);

  if (searchParsed) {
    trackMongoPayload.url_parameters = searchParsed;
    trackMongoPayload.url_parameters_string = window.location.search;
  }

  // Example: '/drug/viagra'
  trackMongoPayload.screen_id = window.location.pathname;

  var payload = {};

  try {

    // No data to put here?
    payload.OS_type = '';

    // Will give us the user agent string in case OS_type and OS_model are not enough/reliable
    payload.OS_version = window.navigator.userAgent;

    // Not long term reliable going forward since userAgent and other stuff will change
    payload.OS_model = getBrowserName();

    // Should tell us mac/windows/ios/android
    payload.manufacturer = window.navigator.oscpu || window.navigator.platform;

    payload.availableMemory = '';

  } catch(err) {

  }

  // Tracking details of User Agent
  payload.userAgent = {};

  try {
    var user = detectService().parse(window.navigator.userAgent);

    if (user) {
      if(user.device) {
        payload.userAgent.device = user.device;
      }
      if(user.os) {
        payload.userAgent.os = user.os;
      }
      if(user.browser) {
        payload.userAgent.browser = user.browser;
      }
    }
  } catch(err) {

  }

  // Tracking requests coming from web
  payload.platform = 'web';

  // SPRINT 139
  // We set 'clinic' in Clinic and 'brochure' in Brochure
  payload.application = 'brochure';

  payload.brochureArrivalUrl = {};
  payload.brochureArrivalUrl.ancestorOrigins = {};
  payload.brochureArrivalUrl.hash = window.location.hash;
  payload.brochureArrivalUrl.host = window.location.host;
  payload.brochureArrivalUrl.hostname = window.location.hostname;
  payload.brochureArrivalUrl.href = window.location.href;
  payload.brochureArrivalUrl.origin = window.location.origin;
  payload.brochureArrivalUrl.pathname = window.location.pathname;
  payload.brochureArrivalUrl.port = window.location.port;
  payload.brochureArrivalUrl.protocol = window.location.protocol;
  payload.brochureArrivalUrl.search = window.location.search;

  payload.brochureArrivalUrl.searchParsed = getUrlParameters(window.location.search);

  payload.brochureOriginatingUrl = document.referrer;

  // Attach google session
  payload.google_client_id = getGoogleClientId();

  trackMongoPayload = Object.assign(trackMongoPayload, payload);
  ajaxGetUserUuid(getUserUuid(), urlToGo);
}

export var trackScreen = function() {
  if(!window.Osano.cm.analytics){
    return;
  }
  trackMongo();
}

function getCookie(cname) {
  var cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  _.forEach(cookies, function(v){
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });

  if (!cookieObj[cname] || cookieObj[cname].length < 1) return "";
  return cookieObj[cname];
}

function setCookie(cname, cvalue, exp_units, type) {
  var d = new Date();
  var exp_milliseconds;

  if (type == 'years') exp_milliseconds = exp_units * 365 * 24 * 60 * 60 * 1000;
  if (type == 'days') exp_milliseconds = exp_units * 24 * 60 * 60 * 1000;
  if (type == 'hours') exp_milliseconds = exp_units * 60 * 60 * 1000;
  if (type == 'minutes') exp_milliseconds = exp_units * 60 * 1000;

  d.setTime(d.getTime() + exp_milliseconds);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + cookies_domain + ";path=/;Secure";
}

var getUrlParameters = function (parameters) {

  var found = false;
  var searchParsed = {};

  if (parameters) {
    var search = parameters.substring(1);
    var params = search.split("&");

    params.forEach(function (param) {
        var pair = param.split("=");
        searchParsed[pair[0]] = pair[1];
        found = true;
    });
  }

  if (found) return searchParsed;
  return null;
}

function getGoogleClientId() {
  var cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  _.forEach(cookies, function(v){
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });

  if (!cookieObj._ga || cookieObj._ga.length < 1) return "";
  return cookieObj._ga;
}

function getUserUuid() {
  var cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  _.forEach(cookies, function(v){
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });

  if (!cookieObj.user_uuid || cookieObj.user_uuid.length < 1) return "";
  return cookieObj.user_uuid;
}

function getSessionUuid() {
  var cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  _.forEach(cookies, function(v){
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });

  if (!cookieObj.session_uuid || cookieObj.session_uuid.length < 1) return "";
  return cookieObj.session_uuid;
}

function ajaxPostTracking(urlToGo) {
  var payload = JSON.stringify(trackMongoPayload);

  var request = new XMLHttpRequest();
  request.open('POST', tracking_base, true);
  request.setRequestHeader("Content-Type", 'application/json');
  request.setRequestHeader("Accept", 'application/json');
  request.setRequestHeader("Authorization", '');
  request.setRequestHeader('X-Service-Code', xServiceCode);
  request.setRequestHeader('X-Client-Name', xClientName);
  request.setRequestHeader('X-Client-Version', 'v2.0.0');
  request.setRequestHeader('X-Api-Key', tracking_api_key);

  request.onload = function(payload) {
    if (this.status >= 200 && this.status < 400) {
      if (urlToGo) {
        if (urlToGo.target) {
          var redirectWindow = window.open(urlToGo.href, urlToGo.target);
          redirectWindow.location;
        } else {
          window.location.href = urlToGo.href;
        }
      }
    } else {
      console.log("Tracking API error: " + payload.target.response + " status " + payload.target.responseText);

      if (urlToGo) {
        if (urlToGo.target) {
          var redirectWindow = window.open(urlToGo.href, urlToGo.target);
          redirectWindow.location;
        } else {
          window.location.href = urlToGo.href;
        }
      }
    }
  };

  request.send(payload);

}

function ajaxGetUserUuid(userUuid, urlToGo) {

  if (userUuid) {

    trackMongoPayload.user_uuid = userUuid;
    ajaxGetSessionUuid(getSessionUuid(), urlToGo);

  } else {

    var request = new XMLHttpRequest();
    request.open('GET', tracking_uuid, true);
    request.setRequestHeader("Content-Type", 'application/json');
    request.setRequestHeader("Accept", 'application/json');
    request.setRequestHeader("X-Api-Key", tracking_api_key);

    request.onload = function(data) {
      if (this.status >= 200 && this.status < 400) {
        var user_uuid = JSON.parse(data.target.response).value;
        setCookie('user_uuid', user_uuid, 2, 'years');
        trackMongoPayload.user_uuid = user_uuid;
        ajaxGetSessionUuid(getSessionUuid(), urlToGo);
      } else {
        console.log('ajaxGetUserUuid error: ',data);
      }
    };

    request.send();
  }
}

function ajaxGetSessionUuid(sessionUuid, urlToGo) {

  if (sessionUuid) {

    trackMongoPayload.session_uuid = sessionUuid;
    ajaxPostTracking(urlToGo);

  } else {
    var request = new XMLHttpRequest();
    request.open('GET', tracking_uuid, true);
    request.setRequestHeader("Content-Type", 'application/json');
    request.setRequestHeader("Accept", 'application/json');
    request.setRequestHeader("X-Api-Key", tracking_api_key);

    request.onload = function(data) {
      if (this.status >= 200 && this.status < 400) {
        var session_uuid = JSON.parse(data.target.response).value;
        setCookie('session_uuid', session_uuid, 30, 'minutes');
        trackMongoPayload.session_uuid = session_uuid;
        ajaxPostTracking(urlToGo);
      } else {
        console.log('ajaxGetSessionUuid error: ',data);
      }
    };

    request.send();

  }
}

export function cacheVariantExtendedID(value, name) {

  var old_variant_extended_ID_string = getCookie('variant_extended_ID');
  var old_variant_extended_ID_object = null;
  var new_variant_extended_ID_object = {};

  try {

    if (old_variant_extended_ID_string) old_variant_extended_ID_object = JSON.parse(old_variant_extended_ID_string);

  } catch(err) {

  }

  if (old_variant_extended_ID_object !== null && typeof old_variant_extended_ID_object === 'object'
    && !Array.isArray(old_variant_extended_ID_object)) new_variant_extended_ID_object = old_variant_extended_ID_object;

  new_variant_extended_ID_object[ name ] = value;

  var new_variant_extended_ID_string = JSON.stringify(new_variant_extended_ID_object);

  console.log("variant_extended_ID: ", new_variant_extended_ID_object);

  setCookie('variant_extended_ID', new_variant_extended_ID_string, 2, 'years');
}
