import { ComponentConstructor, EventMap } from '@splidejs/splide';

export const PaginationPlugin: ComponentConstructor = (splide) => {
  let arrowPrev: HTMLButtonElement | undefined;
  let arrowNext: HTMLButtonElement | undefined;
  let paginationList: HTMLUListElement | undefined;

  function updateArrows(): void {
    if (!splide.options.paginationArrows) {
      return;
    }

    requestAnimationFrame(() => {
      if (!paginationList || !arrowPrev || !arrowNext) {
        return;
      }

      const firstDot = paginationList.firstChild as HTMLLIElement;
      const lastDot = paginationList.lastChild as HTMLLIElement;
      const arrowPositionTop = `${paginationList.offsetTop + firstDot.offsetHeight / 2}px`;

      arrowPrev.style.top = arrowPositionTop;
      arrowPrev.style.left = `${firstDot.offsetLeft - arrowPrev.offsetWidth - 12}px`;

      arrowNext.style.top = arrowPositionTop;
      arrowNext.style.right = `calc(100% - ${lastDot.offsetLeft + lastDot.offsetWidth + arrowNext.offsetWidth + 12}px)`;
    });
  }


  const onResized: EventMap['resized'] = () => {
    updateArrows();

    if (!splide.options.autoPagination) {
      return;
    }

    const requiresPagination = splide.length > 1;
    const showArrows = splide.options.paginationArrows ? requiresPagination : splide.options.arrows;

    if (splide.options.pagination !== requiresPagination) {
      requestAnimationFrame(() => {
        splide.options = {
          pagination: requiresPagination,
          arrows: showArrows,
        };
      });
    }
  };

  const onPaginationMounted: EventMap['pagination:mounted'] = ({ list }) => {
    paginationList = list;
  };

  const onArrowsMounted: EventMap['arrows:mounted'] = (prev, next) => {
    arrowPrev = prev;
    arrowNext = next;
  };

  return {
    setup() {
      splide.on('resized', onResized);
      splide.on('pagination:mounted', onPaginationMounted);
      splide.on('arrows:mounted', onArrowsMounted);
    },
  };
};
