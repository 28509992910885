const headerMobile = document.querySelector('.header__container--mobile');
const headerDesktop = document.querySelector('.header__container--desktop');
const menuBtnMobile = headerMobile.querySelector('.header-navbar__btn-menu');
const menuBtnDesktop = headerDesktop.querySelector('.dropdown-services');
const menuDesktop = headerDesktop.querySelector('.header-navbar__menu__group');
const listNavbarMenu = headerMobile.querySelectorAll('.header-navbar__menu');
const listNavbarSubmenu = headerMobile.querySelectorAll('.header-navbar__submenu');

function toggleMobileMenu() {
  const menu = headerMobile.querySelector('.header-navbar__menu__group');
  const backdrop = headerMobile.querySelector('.header-navbar__menu-backdrop');

  menu.classList.toggle('header-navbar__show-menu');
  backdrop.classList.toggle('header-navbar__show-menu-wrapper');
  menuBtnMobile.classList.toggle('boots-icon-ic-boots-close');

  if(menuBtnMobile.classList.contains('boots-icon-ic-boots-close')){
    menuBtnMobile.setAttribute('aria-label', 'Close navigation menu')
  } else {
    menuBtnMobile.setAttribute('aria-label', 'Navigation menu - opens a new simulated dialog')
  }
}

function toggleDesktopMenu() {
  const menu = document.querySelector('.header__container--desktop .header-navbar__menu__group');
  const wasVisible = menu.classList.contains('header-navbar__show-menu');

  rotateArrow(menuBtnDesktop);
  menu.classList.toggle('header-navbar__show-menu');

  if (wasVisible) {
    document.removeEventListener('click', closeDesktopMenuOnClickOutside);
  } else {
    document.addEventListener('click', closeDesktopMenuOnClickOutside);
  }
}

function closeDesktopMenuOnClickOutside() {
  const isClickInsideElement = menuDesktop.contains(event.target);
  const isOpenList = menuDesktop.classList.contains('header-navbar__show-menu')
  const isClickInsideBtnServices = menuBtnDesktop.contains(event.target)

  if (!isClickInsideElement && isOpenList && !isClickInsideBtnServices) {
    toggleDesktopMenu();
  }
}

function rotateArrow(arrowContainer) {
  const arrowIcon = arrowContainer.querySelector(
    '.boots-icon-ic-boots-chevron-thin-down, .boots-icon-ic-boots-chevron-thin-up'
  );

  arrowIcon.classList.toggle('boots-icon-ic-boots-chevron-thin-down');
  arrowIcon.classList.toggle('boots-icon-ic-boots-chevron-thin-up');
}

function initMenu(menuBtn, toggleMenu, initialOpen = false) {
  let isOpen = initialOpen;

  const wrappedToggle = () => {
    if (isOpen) {
      document.removeEventListener('keydown', keyboardCloseListener);
    } else {
      document.addEventListener('keydown', keyboardCloseListener);
    }

    toggleMenu();
    isOpen = !isOpen;
  };

  const keyboardCloseListener = (event) => {
    if (event.code === 'Escape' || event.keyCode === 27) {
      wrappedToggle();
    }
  };

  menuBtn.addEventListener('click', wrappedToggle);

  menuBtn.addEventListener('keydown', function (event) {
    if (event.code === 'Enter' || event.keyCode === 13) {
      // Don't react to the duplicate "click" event on links.
      event.preventDefault();
      wrappedToggle();
    }
  })
}

initMenu(menuBtnMobile, toggleMobileMenu);
initMenu(menuBtnDesktop, toggleDesktopMenu);

headerMobile.querySelector('.header-navbar__menu-backdrop').addEventListener('click', function () {
  menuBtnMobile.click();
});

listNavbarMenu.forEach(dropdownItem => {
  implementMobileMenuDropdownLogic(dropdownItem, listNavbarMenu)
})

listNavbarSubmenu.forEach(dropdownItem => {
  implementMobileMenuDropdownLogic(dropdownItem, listNavbarSubmenu)
})

function implementMobileMenuDropdownLogic(menuItem, listWithMenu) {
  menuItem.parentNode?.children[0].addEventListener('click', () => {
    rotateArrow(menuItem.parentNode?.children[0])
    menuItem.classList.toggle('header-navbar__show-menu-item');

    listWithMenu.forEach(item => {
      if (item.classList.contains('header-navbar__show-menu-item') && item.parentNode?.children[0].id !== menuItem.parentNode?.children[0].id) {
        rotateArrow(item.parentNode?.children[0])
        item.classList.toggle('header-navbar__show-menu-item')
      }
    })
  })
}
